jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
 
    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });

    
    jQuery(window).resize(function() {
        animateMenu(jQuery(this), 'close');
        if(window.innerWidth > 991){
            jQuery('.col-samecontent').matchHeight({
                byRow: false,
                property: 'height',
                target: null,
                remove: true
            });
        } else {
            $('.col-samecontent').css('height', 'auto');
        }

    });
        

    // MOBILE MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;
        
        if(action == '') {
            jQuery("#side_menu_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {
                jQuery(jQuery("#mobile_menu li").get().reverse()).each(function(i) { 
                    TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
                });
                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, y: '-100%', height: '0'});
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("body").removeClass("menu-open");
                jQuery("#hamburger-icon").removeClass('is-active');
                jQuery("#hamburger-icon-sticky").addClass('closed');
                jQuery("#hamburger-icon-sticky").removeClass('is-active');

            } else {
                jQuery("#mobile_menu li").each(function(i) {
                    TweenLite.to(jQuery(this), 0.2 * i, {ease: Power4.easeInOut, opacity:1});
                });
                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, y: '0%', height: 'auto'});
                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("body").addClass("menu-open");
                jQuery("#hamburger-icon").addClass('is-active');
                jQuery("#hamburger-icon-sticky").removeClass('closed');
                jQuery("#hamburger-icon-sticky").addClass('is-active');

            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper").removeClass('open');
            jQuery(jQuery("#mobile_menu li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
            });
            TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, y: '-100%', height: '0'});
            jQuery("#hamburger-icon").addClass("closed");
            jQuery("body").removeClass("menu-open");
            jQuery("#hamburger-icon").removeClass('is-active');
            jQuery("#hamburger-icon-sticky").addClass('closed');
            jQuery("#hamburger-icon-sticky").removeClass('is-active');
        }
    }

    jQuery('#hamburger-icon-close').click(function() {
        animateMenu(jQuery(this), '');
    });
    
    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery('#mobile-menu a').click(function(e) {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").click(function() {
        animateMenu(jQuery(this), '');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    // SCroll top for contact form
    $('#menu-item-71,#menu-item-171,.menu-item-71,.menu-item-171').click(function(e) {
        e.preventDefault();
        $(this).addClass('visited');
        jQuery('html,body').animate({scrollTop: (jQuery('.contact_wrapper').offset().top - jQuery('#sticky-header').outerHeight())}, '2000');
    });
      // SCroll top for contact form
      $('#menu-item-255,#menu-item-256').click(function(e) {
        animateMenu(jQuery(this), 'close');
        $(this).addClass('visited');
        e.preventDefault();
        setTimeout(function() {
            jQuery('html,body').animate({scrollTop: (jQuery('.contact_wrapper').offset().top - jQuery('#sticky-header').outerHeight())}, '2000');
        }, 400);
    });

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 200) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });
});